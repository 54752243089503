@import '~@adludio/components/dist/Style/_colourPalette';

body,
html,
#root {
  font-family: 'Roboto';
  font-size: 16px;
  width: 100%;
  margin: 0;
  background: #F9F9F9
}

._demoHeaderWrapper {
  position: fixed;
  z-index: 999;
  width: 100%;
  box-sizing: border-box;
  padding: .875rem 3rem;
  background-color: #f3f3f3;
  top: 0;
  left: 0;
  &:before {
    margin:0;
    vertical-align: middle;
    font-size: 2rem;
    text-align: center;
    content: "Internal testing area";
  }
}

._demoSideNavWrapper {
  background: rgb(243, 243, 243);
  width: 3.75rem;
  height: 100vh;
}

._demoAppWrapper {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  min-height: 100vh;
  padding-top: 3.75rem;
}

._demoContentArea {
  flex: 1;
}

button {
  border-radius: 999rem;
}
